<template>
  <div>
    <audio id="myAudio">
      <source :src="audioLink" type="audio/mpeg" />
    </audio>
    <vx-card actionable class="cardx" title="ExEd Call Logs">
      <vs-row style="margin-bottom: 1%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <treeselect
            v-model="selectedSpoc"
            :multiple="true"
            :options="treeDataBDE"
            style="width: 240px; margin-right: 3%"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <div>
            <date-range-picker
              ref="picker"
              :opens="'center'"
              :locale-data="{
                firstDay: 1,
                format: 'DD-MMM-YYYY',
                customRangeLabel: 'Custom Range',
              }"
              :minDate="null"
              :maxDate="getmaxDate()"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="false"
              v-model="date"
              style="width: 240px; margin-right: 3%"
              @update="analizePagination"
            ></date-range-picker>
            <!-- <datepicker id="datepicker" class="datepicker" placeholder="Select Date" v-model="date"></datepicker> -->
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
          style="margin-left:3%"
        >
          <!-- <treeselect
            v-model="initSelectedLevelsIIML"
            :multiple="true"
            :options="treeDataLevelsIIML"
            placeholder = "ExEd Levels"
            style="width: 240px;"
          /> -->
          <treeselect
            v-model="selected_macc_levels"
            :multiple="true"
            :options="treeMAccLevels"
            placeholder = "USP Levels"
            style="width: 240px;"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
            <vs-button
              radius
              color="dark"
              type="gradient"
              icon="search"
            ></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Person name"
                    v-model="person_name"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analizePagination"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" class="searchGap" style="margin-bottom: 5%">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Phone Number"
                    v-model="phone_number"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analizePagination"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </vs-dropdown-menu>
          </vs-dropdown>

          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshData"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-table max-items="10" :data="users">
        <template slot="thead">
          <!-- <vs-th>Person Id</vs-th> -->
          <vs-th>Spoc</vs-th>
          <vs-th>Time</vs-th>
          <vs-th>Contact Type</vs-th>
          <vs-th>Directory</vs-th>
          <vs-th>Call Duration</vs-th>
          <vs-th>Levels</vs-th>
          <vs-th>Person Name</vs-th>
          <vs-th>Phone Number</vs-th>
          <vs-th>Playback</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td>{{tr.person_id}}</vs-td> -->

            <vs-td>{{ getSpocName(tr.user_id) }}</vs-td>

            <vs-td>{{ getMomentDateFromTimestamp(tr.time) }}</vs-td>

            <vs-td>{{ tr.contact_type }}</vs-td>

            <vs-td>{{ tr.directory }}</vs-td>

            <vs-td>{{ timeConvert(tr.call_duration) }}</vs-td>

            <vs-td>{{ tr.mwb_iiml_level }}</vs-td>

            <vs-td>{{ tr.person_name }}</vs-td>

            <vs-td>{{ tr.phone_number }}</vs-td>

            <vs-td>
              <vs-row>
                <div @click="openBigPopupFunction(tr)">
                  <vs-icon
                    class="clickableIcon"
                    icon="info_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>
                <div
                  v-if="tr.call_recording_found !== 0"
                  @click="fetchRecording(tr)"
                >
                  <vs-icon
                    icon="play_arrow"
                    size="small"
                    color="dark"
                  ></vs-icon>
                  <!-- <img -->
                  <!-- src="https://img.icons8.com/ios-filled/24/000000/play.png" -->
                  <!-- /> -->
                </div>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-row style="margin-top: 1%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-pagination
            :total="last_page"
            v-model="selected_link"
          ></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import constants from "../../constants.json";
import Datepicker from "vuejs-datepicker";
import EventBus from "../components/eventbus.js";
import DateRangePicker from "vue2-daterange-picker";
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  data() {
    return {
      dropdownKey: 0,
      audioLink:
        "https://seet-miles-cm-call-recordings.s3.ap-south-1.amazonaws.com/38_1563349034000.mp3",
      date: {
        startDate: "",
        endDate: "",
      },
      person_name: "",
      phone_number: "",
      selected_link: 1,
      pause: false,
      last_page: 0,
      audio: "",
      selectedSpoc: [],
      loadSpoc: [],
      spocList: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      users: [],
      fromUtil: false,
      searchIn: {},
      rawBde: "",
      rawcities: [],
      // initSelectedLevelsIIML: [],
      selected_macc_levels: [],
      // treeDataLevelsIIML: [
      //   {
      //     id: "All",
      //     label: "All",
      //     children: [
      //       { id: "Cold", label: "Cold" },
      //       { id: "Hot", label: "Hot" },
      //       { id: "Warm", label: "Warm" },
      //       { id: "Very Hot", label: "Very Hot" },
      //       { id: "Application Received", label: "Application Received" },
      //       { id: "Exam-Pass", label: "Exam-Pass" },
      //       { id: "Exam-Fail", label: "Exam-Fail" },
      //       { id: "Exam-Pass-Dropout", label: "Exam-Pass-Dropout" },
      //       { id: "Exam-Fail-Dropout", label: "Exam-Fail-Dropout" },
      //       { id: "Exam-Noshow-Dropout", label: "Exam-Noshow-Dropout" },
      //       { id: "Net Enquiry", label: "Net Enquiry" },
      //       { id: "Busy/NL", label: "Busy/NL" },
      //       { id: "Application Approved", label: "Application Approved" },
      //       { id: "Enrolled", label: "Enrolled" },
      //       { id: "Final payment Received", label: "Final payment Received" },
      //       { id: "NA", label: "NA" },
      //       // { id: "Enquiry", label: "Enquiry" },
      //       // { id: "RPO-M7", label: "RPO-M7" },
      //       // { id: "IIML-FA-M7", label: "IIML-FA-M7" },
      //       // { id: "IIML-SF-M7", label: "IIML-SF-M7" },
      //       // { id: "IIML-FT-M7", label: "IIML-FT-M7" },
      //       // { id: "IIML-BA-M7", label: "IIML-BA-M7" },
      //       // { id: "IIML-PM-M7", label: "IIML-PM-M7" },
      //       // { id: "IIML-HR-M7", label: "IIML-HR-M7" },
      //       // { id: "IITR-BF-M7", label: "IITR-BF-M7" },
      //       // { id: "IITR-DB-M7", label: "IITR-DB-M7" },
      //       // { id: "IITM-AA-M7", label: "IITM-AA-M7" },
      //       // { id: "IIMK-CX-M7", label: "IIMK-CX-M7" },
      //       // { id: "IITM-FS-M7", label: "IITM-FS-M7" },
      //       // { id: "IITR-CC-M7", label: "IITR-CC-M7" },
      //       // { id: "IIMK-FT-M7", label: "IIMK-FT-M7" },
      //       // { id: "IIML-AB-M7", label: "IIML-AB-M7" },
      //       // { id: "IIML-SH-M7", label: "IIML-SH-M7" },
      //       // { id: "IITJ-DE-M7", label: "IITJ-DE-M7" },
      //       // { id: "XLRI-HR-M7", label: "XLRI-HR-M7" },
      //       // { id: "IIMI-BA-M7", label: "IIMI-BA-M7" },
      //       // { id: "XLRI-DM-M7", label: "XLRI-DM-M7" },
      //       // { id: "CM-Yet-To-Connect", label: "CM-Yet-To-Connect" },
      //     ],
      //   },
      // ],
      treeMAccLevels: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "U0--", label: "U0--" },
            { id: "U0", label: "U0"},
            { id: "U1", label: "U1"},
            { id: "U1+", label: "U1+"},
            { id: "U2", label: "U2"},
            { id: "U3-", label: "U3-"},
            { id: "U3", label: "U3"},
            { id: "U3+", label: "U3+"},
            { id: "U3++", label: "U3++"},
            { id: "U4-", label: "U4-"},
            { id: "U4", label: "U4"},
            { id: "U4R", label: "U4R"},
            { id: "U5", label: "U5"},
            { id: "U5+", label: "U5+"},
            { id: "U6-", label: "U6-"},
            { id: "U6", label: "U6"},
            { id: "U6+", label: "U6+"},
            { id: "U7-", label: "U7-"},
            { id: "U7", label: "U7"},
            { id: "U8", label: "U8"},
            { id: "U9", label: "U9"},
            { id: "U10", label: "U10"},
          ],
        },
      ],
    };
  },
  components: {
    Datepicker,
    DateRangePicker,
    vSelect,
    Treeselect,
  },
  watch: {
    selectedSpoc: function() {
      this.analizePagination();
    },
    selected_link: function() {
      this.generateTableData();
    },
    // initSelectedLevelsIIML: function () {
    //   this.analizePagination();
    // },
    selected_macc_levels: function () {
      this.analizePagination();
    }
  },
  mounted() {
    // this.cm2Redirect();
    // this.adminRedirect();
    // this.getSpoc();
    this.getSpoctree();
    this.date.startDate = new Date().setDate(new Date().getDate() - 1);
    this.date.endDate = new Date().setDate(new Date().getDate() - 1);
  },
  methods: {
    openBigPopupFunction(tr) {
      console.log(tr);
      this.openBigPopup(tr.mwb_id);
    },
    refreshData() {
      this.person_name = "";
      this.phone_number = "";
      this.analizePagination();
    },
    getMomentDateFromTimestamp(value) {
      var dateString = moment.unix(value / 1000).format("DD-MMM-YYYY HH:mm");
      return dateString;
    },
    getmaxDate() {
      let newdate = new Date(new Date().setDate(new Date().getDate() - 1));
      // newdate = moment.unix(newdate / 1000).format("DD-MM-YYYY");
      // newdate = Date(newdate);
      // console.log(newdate);
      return newdate;
    },
    async buttonVisibility(data) {
      // return true;
      // let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      let connected = false;
      let url = `https://seet-miles-cm-call-recordings.s3.ap-south-1.amazonaws.com/${
        data.user_id
      }_${data.time}.mp3`;
      try {
        let response = await axios({ url: url, method: "get" });
        if (response.status === 200) {
          console.log(response.status);
          connected = true;
        }
      } catch (err) {
        console.log(err);
        connected = false;
      }
      return connected;
      // const promise = new Promise((resolve, reject) => {
      //   axios
      //     .get(url)
      //     .then(response => {
      //       console.log("call log", response);
      //       resolve(true);
      //     })
      //     .catch(error => {
      //       console.log("got an error", error);
      //       reject(false);
      //     });
      // });
      // return promise;
      // axios
      //   .get(url)
      //   .then(response => {
      //     console.log(response);
      //     return true;
      //   })
      //   .catch(error => {
      //     return false;
      //   });
    },
    fetchRecording(call_log) {
      // console.log(call_log);
      let obj = {
        call_log_id: call_log.id,
      };
      let url = `${constants.MILES_CRM_DEV}fetchPreSignedUrl`;
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.data !== null) {
            this.playaudio(response.data.data, call_log);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    playaudio(audiofile, call_log) {
      // console.log(data);
      this.audio = new Audio(audiofile);
      this.audio.onerror = function(error) {
        console.log(error);
        EventBus.$emit("error-finding-calllog");
      };
      EventBus.$emit("open-recording-popup", audiofile, call_log);
    },
    getSpocName(spoc_id) {
      let name = "";
      // console.log(this.rawBde);
      this.rawBde.forEach((spoc) => {
        // console.log(spoc.id);
        if (spoc_id === spoc.id) {
          name = spoc.full_name;
        }
      });
      return name;
    },
    getSpoc() {
      let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response.data);
          response.data.spocs.forEach((spoc) => {
            this.spocList.push(spoc);
            console.log("data", this.spocList)
          });
          if (this.$route.params.selected_obj === undefined) {
            this.selectedSpoc = response.data.spocs;

          } else {
            response.data.spocs.forEach((serverSpoc) => {
              if (serverSpoc.id === this.$route.params.selected_obj.spoc_id) {
                this.selectedSpoc.push(serverSpoc);
              }
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    analizePagination() {
      this.dropdownKey = Math.random()
      if (this.selected_link === 1) {
        this.generateTableData();
      } else {
        this.selected_link = 1;
      }
    },
    getSpoctree() {
      let url = `${constants.ADMIN_SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then(async (response) => {
          console.log(response)
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.sub_team,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              if (
            key != "CM" &&
            key != "EditMobile" &&
            key != "GM" &&
            key != "INT" &&
            key != "null"
          ) {
            this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDE[0].children.push(obj);
          }

            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDE.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.sub_team && bdechid.label !== null) {
                  var obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                  // console.log("null", bdechid)
                }
              });
            });
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.selectedSpoc.push(this.searchIn.name);
          } else {
            this.selectedSpoc.push("All");
          }
          // this.search_API(1);
          this.analizePagination();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    generateTableData() {
      this.$vs.loading();
      let arra = [];
      let searchCriteria = [];
      // this.selectedSpoc.forEach(selSpoc => {
      //   arra.push(selSpoc.id);
      // });
      this.selectedSpoc.forEach((sort) => {
        if (sort === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            child.children.forEach((child2) => {
              arra.push(child2.label);
            });
          });
        } else if (this.rawcities.indexOf(sort) !== -1) {
          this.treeDataBDE[0].children.forEach((child) => {
            if (child.label === sort) {
              child.children.forEach((child2) => {
                arra.push(child2.label);
              });
            }
          });
        } else {
          arra.push(sort);
        }
      });

      // this.initSelectedLevelsIIML.forEach((sort) => {
      //   if (sort === "All") {
      //     this.treeDataLevelsIIML[0].children.forEach((child) => {
      //       searchCriteria.push(child.label);
      //     });
      //   } else {
      //     searchCriteria.push(sort);
      //   }
      // });
      this.selected_macc_levels.forEach((sort) => {
        if (sort === "All") {
          this.treeMAccLevels[0].children.forEach((child) => {
            searchCriteria.push(child.label);
          });
        } else {
          searchCriteria.push(sort);
        }
      });
      let newarr = [];
      let searchCriteriaarr = [];
      arra.forEach((name) => {
        this.rawBde.forEach((spoc) => {
          if (name === spoc.full_name) {
            newarr.push(spoc.id);
          }
        });
      });
      searchCriteria.forEach((newlevel) => {

        // this.rawBde.forEach((spoc) => {
          if (newlevel !== "" ) {
            searchCriteriaarr.push(newlevel);
          }
        // });
        console.log("newlevel", searchCriteriaarr.join())
      });
      let params = {
        iiml_level: searchCriteriaarr.join(),
        spoc_ids: newarr.join(),
        from_date: this.datatoTimestamp(this.date.startDate),
        to_date: this.datatoTimestamp(this.date.endDate),
        phone_number: this.phone_number,
        person_name: this.person_name,
      };
      // console.log(params);
      let url = `${constants.ADMIN_SERVER_API}getAllCallLogs?page=${
        this.selected_link
      }`;
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          response.data.data.data.forEach((each) => {
            each.pause = false;
          });
          this.users = response.data.data.data;
          this.last_page = response.data.data.last_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          // if (error.response) {
          //   // console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    timeConvert(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
  },
};
</script>

<style>
#div-with-loading {
  width: 200px;
  height: 200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}
.vs-radar-text {
  color: darkgray;
  position: relative;
  bottom: 70px;
  left: 35px;
  font-size: larger;
}
.radial_quarter {
  position: relative;
  height: 260px;
}
.lineAreaChartSpline {
  position: relative;
  height: 260px;
}
.vs-radar-thisquarter {
  color: darkgray;
  position: relative;
  bottom: 70px;
  font-size: larger;
  text-align: center;
}
.input-search {
  position: relative;
  right: 10px;
}
.table_heading {
  left: 20px;
  position: relative;
}
.vs-table--tbody-table tr {
  /* background-color:#ffbc00; */
  font-family: sans-serif;
  font-size: smaller;
  /* border: 1px solid black; */
}
.datepicker .vdp-datepicker__calendar {
  z-index: 2000;
}
#spocselect {
  z-index: 2000;
}
.datepicker {
  position: relative;
  /* top: 10px; */
}
</style>
